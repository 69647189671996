import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Doacao = () => {
  const codigoPix = 'sua_chave_pix_aqui'; // Substitua pelo seu código Pix real

  const copiarCodigoPix = () => {
    navigator.clipboard.writeText(codigoPix)
      .then(() => {
        toast.success('Código Pix copiado com sucesso!');
      })
      .catch(() => {
        toast.error('Erro ao copiar o código Pix.');
      });
  };

  return (
    <div className="min-h-screen bg-gray-800 flex flex-col items-center justify-center text-white p-4">
      <h1 className="text-4xl font-bold mb-4 text-center">Obrigado por ajudar.</h1>
      <p className="mb-8 text-center">Sua contribuição faz a diferença!</p>

      <h2 className="text-2xl font-semibold mb-4 text-center">
        PIX - Escaneie o código QR ou clique no botão para copiar o código Pix
      </h2>

      <div className="bg-gray-600 p-8 mb-4">
        {/* Usando a tag <img> com o arquivo SVG */}
        <img src="qr-code.svg" alt="QR Code Pix" className="w-64 h-64" />

        {/* Ou inserindo o código SVG diretamente */}
        {/*
        <svg className="w-64 h-64" viewBox="0 0 100 100">
          <!-- Cole aqui o conteúdo do seu arquivo SVG -->
        </svg>
        */}
      </div>

      <button
        onClick={copiarCodigoPix}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Copiar código Copia e Cola
      </button>

      <ToastContainer />
    </div>
  );
};

export default Doacao;

